
import { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { Form, Input, Button, Select, InputNumber, notification, Modal, Upload } from 'antd';
import { useTranslation } from "react-i18next";
import { CloseOutlined, PlusOutlined, CheckCircleOutlined, DeleteOutlined, LinkOutlined} from "@ant-design/icons";
import { CircularProgress } from '@mui/material';
import TextArea from "antd/lib/input/TextArea";
import 'react-image-picker/dist/index.css'

import "./EditNewProduct.css"

import usePrompt from "../../hooks/usePrompt";

import { convertToHandle, getBase64, uploadMedias, loncaRound, uploadChartImage, getTranslatedFieldName } from "../../helpers";
import { fetchNewProductById, fetchNewProducts, fetchCreateNewProduct } from "../../redux/newProductsSlice";
import { fetchVendor } from "../../redux/vendorSlice";
import { fetchExchangeRates } from "../../redux/exchangeRatesSlice";
import TagSelector from "../../components/TagSelector/TagSelector";
import { current } from "@reduxjs/toolkit";

const EditNewProduct = () => {
  const { t } = useTranslation('translation');
  const [searchParams, _] = useSearchParams();

  const { user } = useSelector((state) => state.user);
  const { genders } = useSelector((state) => state.gender);
  const { tags } = useSelector((state) => state.tags);
  const { vendor } = useSelector((state) => state.vendor);
  const { allProductTypes: productTypes } = useSelector((state) => state.productTypes);
  const { colors } = useSelector((state) => state.colors);
  const { allSeries: seriesMain } = useSelector((state) => state.series);
  const { selectedLanguage } = useSelector((state) => state.user);
  const selectedLanguageCode = selectedLanguage === "tr" ? "tr" : "en";

  const { Option } = Select;
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isDirty, setIsDirty] = useState(false);

  const { data: newProduct } = useQuery(['new-product', id], () => fetchNewProductById(id));

  const [TL_USD_BUYING, setTL_USD_BUYING] = useState();
  const [TL_USD_SELLING, setTL_USD_SELLING] = useState();
  const [product, setProduct] = useState({ color: [], tags: [], gender: [] });
  const [pricetl, setPricetl] = useState(0);
  const [priceusd, setPriceusd] = useState(0);
  const [series, setSeries] = useState("");
  const [status, setStatus] = useState("");
  const [season, setSeason] = useState("");
  const [gender, setGender] = useState("");
  const [fabric, setFabric] = useState("");
  const [modelMeasurements, setModelMeasurements] = useState("");
  const [sampleSize, setSampleSize] = useState("");
  const [productMeasurements, setProductMeasurements] = useState("");
  const [color,  setColor] = useState([]);
  const [product_type,setProductType] = useState({});
  const [ranking, setRanking] = useState(Math.floor(Math.random() * 100));

  const [fileList, setFileList] = useState([]);
  const [chartFileList, setChartFileList] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [selectedNewImage, setSelectedNewImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [skuCode, setSkuCode] = useState();
  const [vendorProductGroupItems, setVendorProductGroupItems] = useState([]);
  const [tab, setTab] = useState("home");

  usePrompt("You have unsaved changes, do you want to leave?", isDirty);

  useEffect(() => {
    fetchExchangeRates({ abbreviation: "TRY" })
      .then(res => {
        setTL_USD_BUYING(res?.data?.rate_usd_buying)
        setTL_USD_SELLING(res?.data?.rate_usd_selling)
      })
  }, [])

  useEffect(() => {
    setTab(searchParams?.get("tab") || "home");
  }, [searchParams?.get("tab")]);

  useEffect(() => {
    setSkuCode(`${vendor?.vendor_initial || ""}${parseInt(vendor?.vendor_product_count || 10000) + 1}`);
  }, [vendor]);
    
  useEffect(() => {
    if(newProduct?.vendor_product_group){
    const filter = { 
      vendor_product_group: newProduct?.vendor_product_group,
      vendor: newProduct?.vendor,
      // exclude itself
      stock_code: { $nin: [newProduct?.stock_code] }
    };
  
    dispatch(fetchNewProducts({ filter })).then((res) => {
      setVendorProductGroupItems(res?.payload?.newProducts);
    });
  }
  }, [dispatch, newProduct?.vendor_product_group, newProduct?.stock_code, newProduct?.vendor]);


  useEffect(() => {
    if (newProduct) {
      form.setFieldsValue({
        supplier_stock_code: newProduct?.stock_code,
        supplier_stock: newProduct?.quantity,
        raw_tr_name: newProduct?.name
      });

      const compoundMargin = ((1 + (vendor?.lonca_vendor_margin / 100)) * (1 + (vendor?.lonca_boutique_margin / 100)));
      const boutiqueMargin = (1 + (vendor?.lonca_boutique_margin / 100));

      switch (newProduct.price_unit) {
        case "USD":
          setPriceusd(loncaRound(newProduct.price * (vendor?.margin_included ? boutiqueMargin : compoundMargin)));
          setPricetl(loncaRound(newProduct.price * TL_USD_SELLING * (vendor?.margin_included ? boutiqueMargin : compoundMargin)));
          break;
        case "TL":
          setPriceusd(loncaRound(newProduct.price * (vendor?.margin_included ? boutiqueMargin : compoundMargin) / TL_USD_BUYING));
          setPricetl(loncaRound(newProduct.price * (vendor?.margin_included ? boutiqueMargin : compoundMargin)));
          break;
        default:
          setPriceusd(0);
          setPricetl(0);
      }

      setStatus(vendor?.status !== 'Active' ? 'Passive' : newProduct?.status);
      setSeries(seriesMain.find((i) => i?.name === newProduct?.series)?._id)
      setSeason(tags.find(t => t?.name === newProduct?.season))
      setColor((colors?.filter(pt => newProduct?.color?.includes(pt?.names?.tr)).length > 0) ?
                colors?.filter(pt => newProduct?.color?.includes(pt?.names?.tr)) :
                colors?.filter(pt => newProduct?.color?.includes(pt?.names?.en)))
      setProductType(productTypes.find(pt => pt?.names?.tr  === newProduct?.product_type) ?
                     productTypes.find(pt => pt?.names?.tr  === newProduct?.product_type) : 
                     productTypes.find(pt => pt?.names?.en === newProduct?.product_type))
      setGender(genders.filter((item) => item.handle === newProduct?.gender?.toLocaleLowerCase()))
      setFabric(newProduct?.fabric);
      setModelMeasurements(newProduct?.model_measurements);
      setSampleSize(newProduct?.sample_size);
      setProductMeasurements(newProduct?.product_measurements);

      setFileList(newProduct.images.map((image, index) => {
        return {
          uid: index+1,
          status: "done",
          url: image,
          name: `Image_${index+1}`,
          existBeforeUpdate: true,
          is_main: index === 0
        }
      }))

      const initialProduct = {
        ...product,
        newProductId: newProduct._id,
        cogs: newProduct?.price_unit === "USD" ? loncaRound(newProduct?.price / (vendor?.margin_included ? compoundMargin : boutiqueMargin)) 
          : loncaRound((newProduct?.price / TL_USD_BUYING) / (vendor?.margin_included ? compoundMargin : boutiqueMargin)),
        price: newProduct?.price_unit === "USD" ? loncaRound(newProduct?.price * (vendor?.margin_included ? boutiqueMargin : compoundMargin)) 
          : loncaRound((newProduct?.price / TL_USD_BUYING) * (vendor?.margin_included ? boutiqueMargin : compoundMargin)),
        color: color,
        gender: gender,
        ranking: ranking,
        vendor: vendor?._id,
        vendor_name: vendor?.name,
        status: status,
        series: series,
        main_image: newProduct.images[0],
        images: newProduct.images,
        stock_code: newProduct.stock_code,
        sku_code: skuCode,
        sub_series: newProduct?.sub_series,
        supplier_stock: newProduct?.quantity || 0,
        names :  {tr: calculateName(newProduct?.name)},
        vendor_site_link: newProduct.vendor_site_link,
        product_type: product_type,
        description_details : {tr : {fabric, model_measurements : modelMeasurements, sample_size : sampleSize, product_measurements : productMeasurements}},
        tags: season ? [season]: []
      }
      setProduct(initialProduct);
    }
  }, [newProduct, series, skuCode, TL_USD_BUYING, TL_USD_SELLING]);

  const updateTabParam = (newTab) => {
    const newUrl = `${location.pathname}?tab=${newTab}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
    setTab(newTab);
  };

  const deleteTag = (tag) => {
    setProduct({ ...product, tags: product.tags.filter((item) => item._id !== tag._id) });
    setIsDirty(true);
  };

  const deleteColor = (color) => {
    setProduct({ ...product, color: product.color.filter((item) => item._id !== color._id) });
    setIsDirty(true);
  };

  const deleteGender = (handle) => {
    setProduct({ ...product, gender: product.gender.filter((item) => item.handle !== handle) });
    setIsDirty(true);
  };

  const handlePick = (image) => {
    if(image?.src?.slice(0, 4) === "data") {
      setSelectedNewImage(image?.src)
      return;
    }
    const newImages = [image.src, ...product.images.filter((i) => i !== image.src)];
    setProduct({ ...product, main_image: image.src, images: newImages });
    setSelectedNewImage(null);
    setIsDirty(true);
  }

  const calculateName = (rawName) => {
    rawName = rawName.split(" ").map(word => `${word[0]?.toUpperCase() || ""}${word?.slice(1)?.toLocaleLowerCase() || ""}`).join(" ");
    return `${skuCode} - ${rawName}`;
  }


  const handleSubSerieStockChange = (newValue, index, key) => {
    const updatedSubSeries = [...product?.sub_series];
    updatedSubSeries[index] = {
        ...updatedSubSeries[index],
        [key]: newValue
    };

    setProduct({ ...product, sub_series: updatedSubSeries }); 
    setIsDirty(true); 
};

  const handleSubmit = async () => {
    setLoading(true)

    
    const isMisfire = vendor?.is_misfire;
    const misfireLimit = vendor?.misfire_limit;

    const totalStock = ((isMisfire && product?.sub_series?.length > 0) ? product?.sub_series?.reduce((acc, crr) => acc + crr?.stock, 0) : product?.stock) || 0;
    const totalSupplierStock = (isMisfire && product?.sub_series?.length > 0) ? product?.sub_series?.reduce((acc, crr) => acc + crr?.supplier_stock, 0) : product?.supplier_stock;
    const limit = (isMisfire && product?.sub_series?.length && misfireLimit > 0) ? misfireLimit: 1;
    
    if (!(totalStock >= limit) && !(totalSupplierStock >= limit) && product?.status === "Active") {
      notification['error']({
        message: (product?.sub_series?.length && misfireLimit > 0) 
          ? `${t(`errors.Minimum required total stock`)}: ${limit}`
          : t(`errors.Active products must have either stock or supplier stock.`),
      });
      setLoading(false);
      return;
    }
 
    let imageLinks = [];

    if ((product?.status === "Active" && vendor?.status === "Passive")) {
      notification['error']({
        message: t(`errors.You can only enter Passive products when the vendor is not Active!`),
      });
      setLoading(false);
      return;
    }

    if (product?.color?.length === 0) {
      notification['error']({
        message: t(`errors.You should specify color`)
      });
      setLoading(false);
      return;
    }

    // new product season tag check
    const seasonTag = product?.tags?.find((tag) => tag?.type?.includes("season"));
    if ((product?.status === "Active" || product?.status === "Passive") && !seasonTag) {
      notification['error']({
        message: t(`errors.Product needs a seasonal tag`),
      });
      setLoading(false);
      return;
    }

    if (priceusd <= 0) {
      notification['error']({
        message: t(`errors.zero_price`),
      });
      setLoading(false);
      return;
    }
        
    if(fileList.length === 0){
      notification['error']({
        message: t(`errors.You need to upload at least one product image`),
      });
      setLoading(false)
      return
    }

    const beforeImageCount = fileList?.filter(f => f?.existBeforeUpdate)?.length;

    let selectedImage = fileList?.find(f => f?.is_main);
    let selectedImageLink = "";

    let existBeforeUpdateMainIndex = fileList?.findIndex(f => f?.is_main);
    let existBeforeUpdateCountTillIndex = fileList?.slice(0, existBeforeUpdateMainIndex + 1)?.filter(f => f?.existBeforeUpdate)?.length
    
    if(fileList?.length) imageLinks = await uploadMedias(
      vendor?.name, 
      fileList?.filter(f => !f?.existBeforeUpdate), 
      skuCode,
      beforeImageCount
    );

    if (imageLinks.length === fileList?.filter(f => !f?.existBeforeUpdate)?.length) {
      notification['success']({
        message: t(`errors.All images uploaded successfully`),
      });
    } else {
      notification['error']({
        message: t(`errors.All images couldn't uploaded successfully`)
      });
    };

    if (selectedImage?.existBeforeUpdate) {
      selectedImageLink = selectedImage?.url;
    } else {
      selectedImageLink = imageLinks[existBeforeUpdateMainIndex - existBeforeUpdateCountTillIndex];
    }

    if (selectedImageLink === "" || selectedImageLink === undefined){
      notification['error']({
        message: t(`errors.You should select Main Image`)
      });
      setLoading(false)
      return
    }

    const required = [
      {
        name: "Series",
        field: "series" 
      },
      {
        name: "COGS",
        field: "cogs" 
      },
      {
        name: "Product Type",
        field: "product_type" 
      }
    ];
    for (const requiredField of required) {
      if(!product[requiredField?.field]) {
        notification['error']({
          message: `${t(`fields.product.${requiredField.name}`)} ${t(`errors.is missing`)}`,
        });
        setLoading(false)
        return;
      }
    }

    let chartImage = "";

    if(chartFileList?.filter(i => !i?.existBeforeUpdate)?.length) chartImage = await uploadChartImage(
      vendor?.name, 
      chartFileList, 
      skuCode
    );

    let productEnterLog = {
      role: user?.role,
      user: user?.full_name,
      type: "Draft"
    }

    const updatedProduct = {
      ...product,
      images: fileList.filter(f => f?.existBeforeUpdate).map(f => f?.url),
      product_enter_log: productEnterLog,
      vendor,
      series: seriesMain?.find(s => s?._id?.toString() === product?.series?.toString()),
    }

    const vendorLastProductCount = vendor?.vendor_product_count;

    fetchCreateNewProduct(updatedProduct, imageLinks, selectedImageLink, chartImage, vendorLastProductCount).then((res) => {
      notification['success']({
        message: t(`errors.Product created successfully`),
      });
      dispatch(fetchVendor({ _id: user.vendor }));
      navigate(-1);
      setLoading(false)
    })
      .catch((err) => {
        if(err?.response?.status === 409) {
          notification['error']({
            message: 'You have possibly created a product for the same vendor in a separate tab! Refreshing the page...',
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          notification['error']({
            message: t(`errors.Product couldn't created successfully`),
          });
        }
        setLoading(false)
      });
    setIsDirty(false);
  };


  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const capitalize = (text) => {
    let words = text.split(" ");
    words = words.map(word => (word[0]?.toUpperCase() || "") + (word?.slice(1)?.toLocaleLowerCase() || ""));
    return words.join(" ");
  }

  return (
    <div className='container'>
      <div className='product-details'>
        <Form
          form={form}
          className="product-form"
          onFinish={handleSubmit}
          layout="vertical"
        >
          <div className="product-header-save">
            <div className="product-tabs">
              <div onClick={() => updateTabParam(`home`)} className={tab === "home" && "selected"}>Home</div>
              <div onClick={() => updateTabParam(`tags`)} className={tab === "tags" && "selected"}>Tags</div>
            </div>
            <h2 className="product-header">{`${newProduct?.name} ${newProduct?.stock_code}`}</h2>
            <div>
              <Button type="primary" disabled= {loading} htmlType="submit" style={{ marginRight: "10px", width: "80px" }}>
                {loading ? <>&nbsp;
                <CircularProgress className='cart-spinner' size='1.2em' />
              </> : t(`buttons.Save`)}
              </Button>
              <Button type="secondary" onClick={() => navigate(-1)}>
                {t(`buttons.Cancel`)}
              </Button>
            </div>
          </div>

          { tab === "home" 
            ?
              <section>
                <div className='form-top'>
                  <div className='form-left'>
                    <div className='inline-inputs'>
                      <Form.Item label={t(`fields.product.Tr Name`)}>
                        <Input
                          value={product?.names?.tr}
                          disabled
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.new_products.Raw Tr Name`)} name="raw_tr_name">
                        <Input
                          required
                          onChange={(e) => {
                            setProduct({ ...product, names : {...product.names, tr : calculateName(e.target.value)}});                  
                            setIsDirty(true);
                          }}>
                        </Input>
                      </Form.Item>
                      <Form.Item label={t(`fields.product.Handle`)}>
                        <Input
                          disabled
                          value={convertToHandle(product?.name)}
                        />
                      </Form.Item>
                      <Form.Item label={t(`fields.new_products.SKU Code`)}>
                        <Input
                          value={skuCode}
                          disabled
                        />
                      </Form.Item>
                    </div>
                    <div className="description_box">
                      <h4 style={{ fontWeight: 800 }}>{t(`placeholders.Description From Supplier`)}</h4>
                      <p style={{ textAlign: 'left' }}>{newProduct?.description}</p>
                      <a target="_blank" rel="noreferrer" href={newProduct?.vendor_site_link}>{newProduct?.vendor_site_link}</a>

                      <div className="supplier_stock_code">
                        <Form.Item name="supplier_stock_code" label={t(`fields.product.Supplier Stock Code`)}>
                          <Input
                            value={product?.supplier_stock_code}
                            min={0}
                            onChange={(e) => {
                              const supplier_stock_code = e.target.value
                              setProduct({ ...product, supplier_stock_code });
                              setIsDirty(true);
                            }}
                            className="input-width"
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div className="description_box stock">
                    { (product?.sub_series?.length > 0  &&  vendor?.is_misfire) ? 
                      <Form.Item label={t(`fields.product.Supplier Stock`)}>
                              {product?.sub_series?.map((subSeries, index) => {
                              return (
                                  <div>
                                      <Form.Item label={subSeries?.series?.name} >
                                                  <InputNumber
                                                  value={subSeries?.supplier_stock}
                                                  min={0}
                                                  onChange={(value) => handleSubSerieStockChange(parseInt(value), index, 'supplier_stock')}
                                                  className="input-width"
                                                  />                  
                                      </Form.Item>
                                  </div>
                              )})}
                      </Form.Item>
                        : 
                      <Form.Item name="supplier_stock" label={t(`fields.product.Supplier Stock`)}>
                        <InputNumber
                          value={product?.supplier_stock}
                          min={0}
                          onChange={(e) => {
                            const supplier_stock = parseInt(e)
                            setProduct({ ...product, supplier_stock });
                            setIsDirty(true);
                          }}
                          className="input-width"
                        />
                      </Form.Item>}
                    </div>
                    <Form.Item label={t(`fields.product.Tr Description`)}>
                      <div className="description_box">
                        <div className="inline-inputs">
                          <Form.Item label={t(`fields.product.Fabric`)}>
                            <TextArea
                              value={product?.description_details?.tr?.fabric}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  description_details: {
                                    tr : {
                                    ...product?.description_details?.tr, 
                                    fabric: capitalize(e.target.value)
                                    }
                                  }
                                });
                                setIsDirty(true);
                              }}
                            />
                          </Form.Item>
                          <Form.Item label={t(`fields.product.Model Measurement`)}>
                            <TextArea
                              value={product?.description_details?.tr?.model_measurements}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  description_details : {
                                    tr: {
                                    ...product?.description_details?.tr,
                                    model_measurements: e.target.value
                                  }
                                }
                                });
                                setIsDirty(true);
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="inline-inputs">
                          <Form.Item label={t(`fields.product.Sample Size`)}>
                            <TextArea
                              value={product?.description_details?.tr?.sample_size}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  description_details : {
                                    tr: {
                                    ...product.description_details?.tr,
                                    sample_size: e.target.value
                                  }
                                }
                                });
                                setIsDirty(true);
                              }}
                            />
                          </Form.Item>
                          <Form.Item label={t(`fields.product.Product Measurement`)}>
                            <TextArea
                              value={product?.description_details?.tr?.product_measurements}
                              onChange={(e) => {
                                setProduct({
                                  ...product,
                                  description_details : {
                                    tr: {
                                    ...product?.description_details?.tr,
                                    product_measurements: e.target.value
                                  }
                                  }
                                });
                                setIsDirty(true);
                              }}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    </Form.Item>

                    <div className="description_box-cogs">
                      <h3>{`${t(`placeholders.Price Unit`)}: ${newProduct?.price_unit}`}</h3>
                      <div className="inline-inputs">
                        <Form.Item label={t(`fields.product.Price(TL)`)}>
                          <Input
                            disabled={true}
                            prefix="₺"
                            value={pricetl}
                          />
                        </Form.Item>
                        <Form.Item label={t(`fields.product.Price(USD)`)}>
                          <Input
                            disabled={true}
                            prefix="$"
                            value={priceusd}
                          />
                        </Form.Item>
                      </div>
                    </div>
                                  
                    <div className="description_box-vendor-product-group">
                    <Form.Item label={t(`fields.product.Vendor Product Group`)} className="product-group-box">
                      <div className="product-group-container" >
                        {vendorProductGroupItems?.slice(0,10).map((item) => (
                            <div className="product-group-item" key={item.handle}  onClick={(e) => {
                              e.preventDefault(); // prevent the default context menu from showing up
                              window.open(`/new-product/${item?._id}`, "_blank")}} 
                          >
                              <span className="product-group-item-title">{item.name}</span>
                              <span className="product-group-item-color">{item.color[0] ? item.color[0].color : 'NO COLOR MIGHT EXPLODE'}</span>
                            </div>
                          ))}
                      </div>
                    </Form.Item>
                    </div>    
                  </div>
                  <div className='form-right'>
                    <Form.Item name="status" label={t(`fields.product.Status`)}>
                      <Input
                        disabled
                        placeholder={t(`status.${product?.status}`)}
                      >
                      </Input>
                    </Form.Item>      
                    {!product?.product_type ? 
                    (newProduct?.product_type ?
                    <div>
                      {<p>{t("placeholders.Product Type from Supplier")}:  {newProduct?.product_type}</p>}
                    </div> :
                    <div>
                    {<p>{t("placeholders.Product Type from Supplier")}:  {t("placeholders.No Product Type Info")}</p>}
                  </div>) : 
                  null
                  }
                    <Form.Item label={t(`fields.product.Product Type`)}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        value={product?.product_type?._id}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            product_type: productTypes.find((type) => type?._id === e)
                          });
                          setIsDirty(true);
                        }}
                      >
                        {productTypes?.slice().sort((a, b) => t(`product_types.${a.name}`).localeCompare(t(`product_types.${b.name}`))).map((type, index) => (
                          <Option key={'type-select' + index} value={type._id}>
                            {t(`product_types.${type.name}`)}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name="vendor" label={t(`fields.new_products.Vendor`)}>
                      <Input
                        disabled
                        value={newProduct?.vendor}
                        placeholder={newProduct?.vendor}
                      >
                      </Input>
                    </Form.Item>
              {!product?.series  ?
              (newProduct?.series ? 
              <div>
              {<p>{t("placeholders.Series from Supplier")}: {product?.series || newProduct?.series}</p>}
              </div> : 
              <div>
              {<p>{t("placeholders.Series from Supplier")}: {t("placeholders.No Series Info")}</p>}
              </div>) : 
              null
              } 
                    <Form.Item label="Series">
                      <Select
                        value={product?.series}
                        onChange={(e) => {
                          setProduct({
                            ...product,
                            series: e
                          });
                          setIsDirty(true);
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children?.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        }
                      >
                        <Option value={null}>None</Option>
                        {seriesMain.map((serie, index) => (
                          <Option key={'series-select' + index} value={serie?._id}>
                            {serie?.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>

                    {((newProduct?.color?.length > 0) ?
                    <div>
                      {<p>{t(`placeholders.Colors from Supplier`)}: {newProduct?.color?.join(', ') }</p>}
                    </div> :
                    <div> {<p>{t(`placeholders.Colors from Supplier`)}: {t(`placeholders.No Color Info`)}</p>}
                    </div>)}
                    <Form.Item name="color" label={t(`fields.product.Colors`)}>
                      <Select
                        onChange={(e) => {
                          if (!product?.color?.find(color => color._id === e)) {
                            setProduct({ ...product, color: [...product.color,  colors.find((a) => a?._id === e)] });
                          };
                          setIsDirty(true);
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children?.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        }
                        placeholder={t(`placeholders.Add Color`)}
                      >
                        {colors?.slice().sort((a, b) => a.color.localeCompare(b.color)).filter(item1 => !product?.color?.find(item2 => item1.color === item2.color)).map((color, index) => (
                          <Option key={'colors-select' + index} value={color._id}>
                            {selectedLanguage?.code === "tr" ? color?.names?.tr : (color?.names?.en || color?.color)}
                          </Option>
                        ))}
                      </Select>
                      <div className="multi-container">
                        {product?.color?.map((color, index) => (
                          <div className="multi-item" key={index}>
                            <CloseOutlined onClick={() => deleteColor(color)} />
                            {selectedLanguage?.code === "tr" ? color?.names?.tr : (color?.names?.en || color?.color)}
                          </div>
                        ))}
                      </div>
                    </Form.Item>

                  {!(product?.gender?.length > 0) ? 
                    (newProduct?.gender ?
                    <div>
                      {<p>{t("placeholders.Gender from Supplier")}:  {newProduct?.gender}</p>}
                    </div> :
                    <div>
                    {<p>{t("placeholders.Gender from Supplier")}:  {t("placeholders.No Gender Info")}</p>}
                  </div>) : 
                  null
                  }
                    {product?.gender && <Form.Item name="gender" label={t(`fields.product.Genders`)}>
                      <Select
                        onChange={(e) => {
                          const gender = genders.find((item) => item.handle === e);
                          form.setFieldsValue({ gender: null });


                          setProduct({ ...product, gender: [...product.gender, gender] });

                          setIsDirty(true);
                        }}
                        showSearch
                        optionFilterProp="children"
                        placeholder={t(`placeholders.Add Gender`)}
                      >

                        {genders?.slice().filter(item1 => !product?.gender?.find(item2 => item1?.handle === item2?.handle)).map((gender, index) => (
                          <Option key={gender.handle} value={gender.handle}>
                            {t(`genders.${gender?.names?.en}`)}
                          </Option>
                        ))}
                      </Select>
                      <div className="multi-container">
                        {product?.gender?.length > 0 && product?.gender?.map((gender) => (
                          <div className="multi-item" key={gender.handle}>
                            <CloseOutlined onClick={() => deleteGender(gender.handle)} />
                            {t(`genders.${gender?.names?.en}`)}
                          </div>
                        ))}
                      </div>
                    </Form.Item>}

                    <Form.Item name="tags" label={t(`fields.product.Tags`)}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        onChange={(e) => {
                          if (!product.tags.find(tag => tag._id === e)) {
                            setProduct({ ...product, tags: [...product.tags, tags.find((a) => a?._id === e)] });
                          };
                          setIsDirty(true);
                        }}
                        placeholder={t(`placeholders.Add Tag`)}
                      >
                          {tags?.slice()?.filter(tag => !tag?.general_use)?.sort((a, b) => a.name.localeCompare(b.name)).map((tag, index) => (
                          <Option key={'tag-select' + index} value={tag?._id}>
                          {`${t(`${tag?.names?.[selectedLanguageCode] || tag?.names?.en || tag?.name}`)}`}
                          </Option>
                        ))}
                      </Select>
                      <div className="multi-container">
                        {product?.tags?.map((tag, index) => (
                          <div className="multi-item" key={index}>
                            { !tag.general_use ? <CloseOutlined onClick={() => deleteTag(tag)} /> : null}
                            {`${t(`${tag?.names?.[selectedLanguageCode] || tag?.names?.en || tag?.name}`)}`}
                          </div>
                        ))}
                      </div>
                    </Form.Item>

                    <Form.Item name="Size Chart" label={t(`fields.product.size_chart`)}>
                      <Upload
                        listType="picture-card"
                        multiple={true}
                        fileList={chartFileList}
                        onPreview={handlePreview}
                        onChange={({ file: updatedFile, fileList: newFileList }) => {
                          if(chartFileList.length < newFileList.length) {
                            setChartFileList(currentList => [...currentList, { ...updatedFile, status: "done" }]);
                          } else if (chartFileList.length > newFileList.length){
                            setChartFileList(newFileList.filter(f => f.status !== "removed"));
                          }
                        }}
                      >
                          { chartFileList?.length < 1 ? <div>
                          <PlusOutlined />
                          <div
                            className="plus-outlined-margin"
                          >
                            Upload
                          </div></div> : null }
                      </Upload>

                    </Form.Item>
                  </div>
                </div>

                <div className="form-bottom">
                  <p>{t(`placeholders.Add Additional Photos for Product`)}</p>
                  <p>{t(`placeholders.To select main image or delete an image, hover the cursor over the product and use buttons`)}</p>

                  <Upload
                    listType="picture-card"
                    multiple={true}
                    fileList={fileList}
                    onPreview={handlePreview}
                    itemRender={(originNode, file, fileList, { remove }) => {
                      return (
                        <div className={`single-image ${file?.is_main ? "main-img" : ""}`} onClick={(e) => {handlePreview(file)}}>
                          <img width={86} height={86} src={file.url} alt={file.uid}/>
                          <div className="shadow-img">
                            <CheckCircleOutlined className="main-selector" onClick={(e) => {
                              e.stopPropagation();
                              setFileList(list => [
                                { ...file, is_main: true },
                                ...(list.filter(f => f?.uid !== file?.uid).map(f => ({ ...f, is_main: false })))
                              ]);
                            }}/>
                            <DeleteOutlined className="trash-selector" onClick={(e) => {
                              e.stopPropagation();
                              remove();
                            }}/>
                          </div>
                        </div>
                      )
                    }}
                    onChange={async ({ file: updatedFile, fileList: newFileList }) => {
                      if(fileList.length < newFileList.length) {
                        const url = await getBase64(updatedFile.originFileObj)
                        setFileList(currentList => [...currentList, { ...updatedFile, status: "done", url }]);
                      } else if (fileList.length > newFileList.length){
                        let updatedList = newFileList.filter(f => f.status !== "removed");

                        if(updatedFile?.is_main) {
                          updatedList = [ { ...updatedList[0], is_main: true }, ...updatedList.slice(1) ]
                        }

                        setFileList(updatedList);
                      }
                    }}
                  >
                    <div>
                      <PlusOutlined />
                      <div
                        className="plus-outlined-margin"
                      >
                        {t(`buttons.Upload`)}
                      </div>
                    </div>
                  </Upload>

                  <Modal 
                    visible={previewVisible} 
                    title={
                      <span>
                        <a href={previewImage} target="_blank" rel="noopener noreferrer">
                          {previewTitle} <LinkOutlined />
                        </a>
                      </span>
                    } 
                    footer={null} 
                    onCancel={() => setPreviewVisible(false)}>
                    <img
                      alt="example"
                      className="input-width"
                      src={previewImage}
                    />
                  </Modal>

                </div>
              </section>
            :
              <TagSelector  product={product} setProduct={setProduct} setIsDirty={setIsDirty} />
          }

        </Form>
      </div>
    </div>
  );

};

export default EditNewProduct;